import React from 'react';
import getMuiComponents from 'components/MarkdownDiff/utils/getMuiComponents';
import { diffTrimmedLines } from 'diff';
import { unified } from 'unified';
import parseMarkdown from 'remark-parse';
import rehypeStringify from 'rehype-stringify';
import remarkGfm from 'remark-gfm';
import remarkBreaks from 'remark-breaks';
import remarkRehype from 'remark-rehype';
import rehypeRaw from 'rehype-raw';
import Markdown from 'components/MarkdownDiff/styled/Markdown.styled';

interface MarkdownDiffProps {
  newContent: string;
  existingContent?: string;
}

function MarkdownDiff({ newContent, existingContent }: MarkdownDiffProps) {
  const renderDiffMarkdown = () => {
    if (!existingContent) {
      return newContent;
    }

    return diffTrimmedLines(existingContent, newContent)
      .map((part) => {
        const htmlContent = unified()
          .use(parseMarkdown)
          .use(remarkGfm)
          .use(remarkBreaks)
          .use(remarkRehype)
          .use(rehypeStringify)
          .processSync(part.value)
          .toString();

        if (part.added) {
          return `<div class="added-part">${htmlContent}</div>`;
        } else if (part.removed) {
          return `<div class="removed-part">${htmlContent}</div>`;
        }

        return htmlContent;
      })
      .join('');
  };

  return (
    <Markdown
      remarkPlugins={[remarkGfm, remarkBreaks, remarkRehype]}
      rehypePlugins={[rehypeRaw]}
      components={getMuiComponents()}
    >
      {renderDiffMarkdown()}
    </Markdown>
  );
}

export default MarkdownDiff;
