import React, { ReactNode, useState } from 'react';
import Box from '@mui/material/Box';
import TopBar from 'components/Layout/TopBar/TopBar';
import AppDrawer from 'components/Layout/AppDrawer/AppDrawer';
import ProjectSwitchSelect from 'components/Layout/ProjectSwitchSelect/ProjectSwitchSelect';
import {
  topBarHeight,
  topBarSpacingsOffset,
} from 'components/Layout/utils/consts';
import PathBreadcrumbs from 'components/Layout/PathBreadcrumbs/PathBreadcrumbs';
import { Divider, SxProps, useTheme } from '@mui/material';

interface LayoutProps {
  children: ReactNode;
  sideSection?: ReactNode;
  topBarChildren?: ReactNode;
  topBarSx?: SxProps;
}

function Layout({
  children,
  sideSection,
  topBarChildren,
  topBarSx,
}: LayoutProps) {
  const theme = useTheme();

  const [isMobileOpen, setIsMobileOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false);

  const handleDrawerClose = () => {
    setIsClosing(true);
    setIsMobileOpen(false);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const handleDrawerToggle = () => {
    if (isClosing) {
      return;
    }
    setIsMobileOpen(!isMobileOpen);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        p: 1,
        backgroundColor: theme.vars.palette.background.grey,
        minHeight: '100vh',
      }}
    >
      <TopBar handleDrawerToggle={handleDrawerToggle}>
        <Divider orientation="vertical" />
        <ProjectSwitchSelect />
        <Divider orientation="vertical" />
        <Box sx={{ display: 'flex', ...topBarSx }}>
          <PathBreadcrumbs />
          {topBarChildren}
        </Box>
      </TopBar>
      <Box sx={{ display: 'flex', pt: 1 }}>
        <AppDrawer
          isMobileOpen={isMobileOpen}
          handleDrawerClose={handleDrawerClose}
          handleDrawerTransitionEnd={handleDrawerTransitionEnd}
          sideSection={sideSection}
        />
        <Box
          sx={{
            flexGrow: 1,
            minHeight: `calc(100vh - ${topBarHeight}px - ${topBarSpacingsOffset}px)`,
          }}
        >
          {children}
        </Box>
      </Box>
    </Box>
  );
}

export default Layout;
