import React, { Dispatch, SetStateAction } from 'react';
import { Box, Button, Typography, useTheme } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CircleTwoToneIcon from '@mui/icons-material/CircleTwoTone';
import { t } from 'i18next';
import groupArticlesByOrigin from 'pages/Articles/utils/groupArticlesByOrigin';
import SideSection from 'components/Layout/AppDrawer/SideSection/SideSection';
import Label from 'components/Layout/AppDrawer/SideSection/Label/Label';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { articles, currentArticle } from 'store/articles/selectors';
import ActionButton from 'common/ActionButton/ActionButton';
import { setCurrentArticleById } from 'store/articles/articles';
import { Article } from 'store/articles/interfaces';
import { currentBranch } from 'store/branches/selectors';
import { fetchArticles } from 'store/articles/thunks';
import { currentProject } from 'store/projects/selectors';

interface SideSectionContentProps {
  setIsArticleDialogOpen: Dispatch<SetStateAction<boolean>>;
}

function SideSectionContent({
  setIsArticleDialogOpen,
}: SideSectionContentProps) {
  const theme = useTheme();
  const allArticles = useAppSelector(articles);
  const activeArticle = useAppSelector(currentArticle);
  const activeBranch = useAppSelector(currentBranch);
  const project = useAppSelector(currentProject);
  const dispatch = useAppDispatch();

  const setCurrentArticle = (article: Article) => {
    dispatch(setCurrentArticleById({ articleId: article.id }));
  };

  const fetchMasterArticles = () => {
    if (!project) {
      return;
    }

    dispatch(fetchArticles({ project: project.id }));
  };

  return (
    <SideSection
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        p: 1,
      }}
    >
      <Box>
        {!activeBranch ? (
          <Label>{t('select_branch_to_see_articles')}</Label>
        ) : allArticles?.length ? (
          groupArticlesByOrigin(allArticles).map((articleGroup) => (
            <React.Fragment key={articleGroup[0]?.id}>
              {articleGroup.map((article) => (
                <ActionButton
                  key={article.id}
                  onClick={() => setCurrentArticle(article)}
                  sx={{
                    bgcolor:
                      activeArticle?.id === article.id
                        ? theme.palette.action.selected
                        : 'transparent',
                  }}
                >
                  <CircleTwoToneIcon
                    fontSize="small"
                    sx={{ color: theme.palette.success.lighter }}
                  />
                  <Typography
                    variant="body2"
                    color="text.primary"
                    textAlign="left"
                  >
                    {article.title}
                  </Typography>
                </ActionButton>
              ))}
            </React.Fragment>
          ))
        ) : (
          <Label>{t('no_articles')}</Label>
        )}
        <Button sx={{ width: '100%' }} onClick={fetchMasterArticles}>
          Switch to masters articles
        </Button>
      </Box>
      <Button
        variant="contained"
        size="large"
        color="inherit"
        startIcon={<AddCircleOutlineIcon />}
        onClick={() => setIsArticleDialogOpen(true)}
        sx={{
          m: 1,
        }}
      >
        {t('new_article')}
      </Button>
    </SideSection>
  );
}

export default SideSectionContent;
