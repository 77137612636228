import React, { useEffect, forwardRef, useMemo } from 'react';
import {
  Controller,
  useForm,
  useWatch,
  UseFormSetError,
} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { t } from 'i18next';
import { InferType } from 'yup';
import { getErrorText } from 'helpers/getErrorText';
import articleFormSchema from 'components/Forms/ArticleForm/utils/ArticleFormSchema';
import defaultValues from 'components/Forms/ArticleForm/utils/defaultValues';
import { ArticleOrigin, ArticleParent } from 'store/articles/interfaces';
import Grid from '@mui/material/Grid2';
import { DefaultBranch } from 'store/branches/interfaces';
import { useAppSelector } from 'store/hooks';
import { articles } from 'store/articles/selectors';
import { currentProject } from 'store/projects/selectors';
import { branches } from 'store/branches/selectors';

export type FormData = InferType<typeof articleFormSchema>;

interface ArticleFormProps {
  onSubmit: (data: FormData, setError: UseFormSetError<FormData>) => void;
}

const ArticleForm = forwardRef<HTMLFormElement, ArticleFormProps>(
  ({ onSubmit }, ref) => {
    const allArticles = useAppSelector(articles);
    const allBranches = useAppSelector(branches);
    const project = useAppSelector(currentProject);

    const {
      control,
      handleSubmit,
      formState: { errors },
      setError,
      setValue,
    } = useForm<FormData>({
      resolver: yupResolver(articleFormSchema),
      mode: 'onBlur',
      defaultValues,
    });

    const currentBranch = useWatch({ control, name: 'branch' });
    const isMasterBranch =
      currentBranch === DefaultBranch.master || !currentBranch;

    useEffect(() => {
      if (isMasterBranch) {
        setValue('parent', ArticleParent.none);
        setValue('origin', ArticleOrigin.none);
      }
    }, [currentBranch, setValue, isMasterBranch]);

    return (
      <Box
        component="form"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          height: '100%',
          gap: 1.5,
        }}
        onSubmit={handleSubmit((data) => onSubmit(data, setError))}
        ref={ref}
      >
        <Grid container spacing={1.5}>
          <Grid size={12}>
            <Controller
              name="title"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  type="text"
                  label={t('article_title')}
                  variant="outlined"
                  error={!!errors.title}
                  helperText={getErrorText(errors.title)}
                />
              )}
            />
          </Grid>
          <Grid size={12}>
            <Controller
              name="branch"
              control={control}
              render={({ field }) => (
                <FormControl fullWidth>
                  <InputLabel id="branch-label">{t('branch')}</InputLabel>
                  <Select
                    {...field}
                    fullWidth
                    labelId="branch-label"
                    label={t('branch')}
                    variant="outlined"
                  >
                    <MenuItem
                      key={DefaultBranch.master}
                      value={DefaultBranch.master}
                    >
                      {t('master')}
                    </MenuItem>
                    {allBranches?.map((branch) => (
                      <MenuItem key={branch['@id']} value={branch['@id']}>
                        {branch.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
          <Grid size={12}>
            <Controller
              name="origin"
              control={control}
              render={({ field }) => (
                <FormControl fullWidth>
                  <InputLabel id="origin-label">{t('origin')}</InputLabel>
                  <Select
                    {...field}
                    fullWidth
                    labelId="origin-label"
                    label={t('origin')}
                    variant="outlined"
                    disabled={isMasterBranch}
                  >
                    <MenuItem
                      key={ArticleOrigin.none}
                      value={ArticleOrigin.none}
                    >
                      {t('none')}
                    </MenuItem>
                    {allArticles?.map((article) => (
                      <MenuItem key={article.id} value={article['@id']}>
                        {article.title}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
          <Grid size={12}>
            <Controller
              name="parent"
              control={control}
              render={({ field }) => (
                <FormControl fullWidth>
                  <InputLabel id="parent-label">
                    {t('parent_article')}
                  </InputLabel>
                  <Select
                    {...field}
                    fullWidth
                    labelId="parent-label"
                    label={t('parent_article')}
                    variant="outlined"
                    disabled={isMasterBranch}
                  >
                    <MenuItem
                      key={ArticleParent.none}
                      value={ArticleParent.none}
                    >
                      {t('none')}
                    </MenuItem>
                    {allArticles?.map((article) => (
                      <MenuItem key={article.id} value={article['@id']}>
                        {article.title}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
          <Grid size={12}>
            <Controller
              name="language"
              control={control}
              render={({ field }) => (
                <FormControl fullWidth>
                  <InputLabel id="language-label">{t('language')}</InputLabel>
                  <Select
                    {...field}
                    fullWidth
                    labelId="language-label"
                    label={t('language')}
                    variant="outlined"
                    error={!!errors.language}
                  >
                    {project?.availableLanguages?.map((language) => (
                      <MenuItem key={language} value={language}>
                        {t(`language_${language}`)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
        </Grid>
      </Box>
    );
  },
);

ArticleForm.displayName = 'ArticleForm';

export default ArticleForm;
