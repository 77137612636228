export enum DefaultBranch {
  master = 'master',
}

export enum BrandStatus {
  draft = 'draft',
  merged = 'merged',
  closed = 'closed',
}

export interface BranchesState {
  isRequestPending: boolean;
  branches: Branch[] | null;
  currentBranch: Branch | null;
}

export interface Branch {
  '@id': string;
  '@type': string;
  id: string;
  project: string | null;
  name: string;
  status: BrandStatus;
  author: string;
  toBeDeleted: string[];
}

export type CreateBranchPayload = Omit<
  Branch,
  '@id' | '@type' | 'id' | 'status' | 'toBeDeleted' | 'author'
>;

export type EditBranchPayload = Omit<Branch, '@id' | '@type'>;

export type DeleteBranchPayload = Pick<Branch, 'id'>;
