import React, { useRef } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { t } from 'i18next';
import { UseFormSetError } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { isRequestPending } from 'store/branches/selectors';
import { Branch } from 'store/branches/interfaces';
import { currentProject } from 'store/projects/selectors';
import BranchForm, { FormData } from 'components/Forms/BranchForm/BranchForm';
import mapViolationsToFormErrors from 'helpers/mapViolationsToFormErrors';
import useDisplayNotification from 'hooks/useDisplayNotification';
import { createBranch } from 'store/branches/thunks';

interface BranchDialogProps {
  isDialogOpen: boolean;
  handleDialogClose: () => void;
  branchToEdit?: Branch | null;
}

function BranchDialog({
  isDialogOpen,
  handleDialogClose,
  branchToEdit = null,
}: BranchDialogProps) {
  const requestPending = useAppSelector(isRequestPending);
  const project = useAppSelector(currentProject);

  const dispatch = useAppDispatch();
  const { displaySuccessNotification, displayErrorNotification } =
    useDisplayNotification();

  const formRef = useRef<HTMLFormElement | null>(null);

  const handleFormSubmit = async (
    formData: FormData,
    setError: UseFormSetError<FormData>,
  ) => {
    if (!project) {
      return;
    }

    try {
      await dispatch(
        createBranch({
          ...formData,
          project: project['@id'],
        }),
      ).unwrap();

      displaySuccessNotification(t('success'));
      handleDialogClose();
    } catch (error) {
      mapViolationsToFormErrors<FormData>(error, setError);
      displayErrorNotification(error);
    }
  };

  const handleSubmitClick = () => {
    if (formRef.current) {
      formRef.current.requestSubmit();
    }
  };

  return (
    <Dialog
      open={isDialogOpen}
      onClose={handleDialogClose}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle sx={{ pb: 1 }} color="primary">
        {branchToEdit ? t('edit_branch') : t('new_branch')}
      </DialogTitle>
      <DialogContent sx={{ pb: 1 }}>
        <Box pt={1}>
          <BranchForm onSubmit={handleFormSubmit} ref={formRef} />
        </Box>
      </DialogContent>
      <DialogActions sx={{ px: 3, pb: 2 }}>
        <Button onClick={handleDialogClose} disabled={requestPending}>
          {t('cancel')}
        </Button>
        <Button autoFocus onClick={handleSubmitClick} disabled={requestPending}>
          {t('save')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default BranchDialog;
