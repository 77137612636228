import React, { useEffect, useRef, useState } from 'react';
import Grid from '@mui/material/Grid2';
import Layout from 'components/Layout/Layout';
import ArticleDialog from 'components/Dialogs/ArticleDialog/ArticleDialog';
import MarkdownDiff from 'components/MarkdownDiff/MarkdownDiff';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { editArticle } from 'store/articles/thunks';
import { currentArticle } from 'store/articles/selectors';
import { setCurrentArticleById } from 'store/articles/articles';
import RoundedBox from 'common/RoundedBox/RoundedBox';
import ControlsSection from 'pages/Articles/ControlsSection/ControlsSection';
import SideSectionContent from 'pages/Articles/SideSectionContent/SideSectionContent';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import { Button, Fab, TextField } from '@mui/material';
import { t } from 'i18next';
import useDisplayNotification from 'hooks/useDisplayNotification';

function Articles() {
  const article = useAppSelector(currentArticle);
  const dispatch = useAppDispatch();
  const { displaySuccessNotification, displayErrorNotification } =
    useDisplayNotification();

  const articleEditorRef = useRef<HTMLInputElement>(null);

  const [isArticleDialogOpen, setIsArticleDialogOpen] = useState(false);
  const [currentArticleContent, setCurrentArticleContent] = useState('');

  const isEditArticleDisabled =
    currentArticleContent === article?.content || !article;

  useEffect(() => {
    if (!article) {
      return;
    }

    setCurrentArticleContent(article.content);
  }, [article]);

  const handleArticleDialogClose = () => {
    setIsArticleDialogOpen(false);
  };

  const handleUpdateArticleContent = async () => {
    if (isEditArticleDisabled) {
      return;
    }

    try {
      const updatedArticle = await dispatch(
        editArticle({
          content: currentArticleContent,
          id: article.id,
        }),
      ).unwrap();

      dispatch(setCurrentArticleById({ articleId: updatedArticle.id }));

      displaySuccessNotification(t('success'));
    } catch (error) {
      displayErrorNotification(error);
    }
  };

  return (
    <>
      <Layout
        sideSection={
          <SideSectionContent setIsArticleDialogOpen={setIsArticleDialogOpen} />
        }
        topBarChildren={
          <Button
            variant="contained"
            disabled={isEditArticleDisabled}
            onClick={handleUpdateArticleContent}
          >
            {t('save_publish')}
          </Button>
        }
        topBarSx={{
          flexGrow: 1,
          justifyContent: 'space-between',
          display: { xs: 'none', md: 'flex' },
        }}
      >
        <Grid container direction="column" spacing={1} sx={{ height: '100%' }}>
          <Grid size={12}>
            <ControlsSection
              articleEditorRef={articleEditorRef}
              currentArticleContent={currentArticleContent}
              setCurrentArticleContent={setCurrentArticleContent}
            />
          </Grid>
          <Grid size={12} sx={{ display: 'flex', flexGrow: 1 }}>
            <Grid container sx={{ flexGrow: 1 }} spacing={1}>
              <Grid size={{ xs: 12, md: 6 }}>
                <RoundedBox sx={{ p: { xs: 4, xl: 6 }, height: '100%' }}>
                  {article && (
                    <TextField
                      inputRef={articleEditorRef}
                      multiline
                      fullWidth
                      type="text"
                      variant="outlined"
                      value={currentArticleContent}
                      onChange={(e) => setCurrentArticleContent(e.target.value)}
                      sx={{
                        height: '100%',
                        '& .MuiOutlinedInput-root': {
                          alignItems: 'start',
                          height: '100%',
                          padding: 0,
                          '& fieldset': {
                            border: 'none',
                          },
                          '&:hover fieldset': {
                            border: 'none',
                          },
                          '&.Mui-focused fieldset': {
                            border: 'none',
                          },
                        },
                      }}
                    />
                  )}
                </RoundedBox>
              </Grid>
              <Grid size={{ xs: 12, md: 6 }}>
                <RoundedBox sx={{ p: { xs: 4, xl: 6 }, height: '100%' }}>
                  {article && (
                    <MarkdownDiff
                      newContent={currentArticleContent}
                      existingContent={article.content}
                    />
                  )}
                </RoundedBox>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Fab
          size="medium"
          color="primary"
          sx={{
            position: 'fixed',
            bottom: 12,
            right: 12,
            display: { xs: 'flex', md: 'none' },
          }}
          disabled={isEditArticleDisabled}
          onClick={handleUpdateArticleContent}
        >
          <SaveOutlinedIcon />
        </Fab>
      </Layout>
      <ArticleDialog
        isDialogOpen={isArticleDialogOpen}
        handleDialogClose={handleArticleDialogClose}
      />
    </>
  );
}

export default Articles;
