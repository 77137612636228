import { useEffect } from 'react';
import { useAppDispatch } from 'store/hooks';
import { setIsAuthorized, setToken } from 'store/authorization/authorization';
import { setCurrentProjectById } from 'store/projects/projects';
import { setCurrentOrganizationById } from 'store/organizations/organizations';
import { setCurrentBranchById } from 'store/branches/branches';
import { setCurrentArticleById } from 'store/articles/articles';
import { useNavigate } from 'react-router';
import useResetState from 'hooks/useResetState';

export default function useLocalStorageSync() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const resetState = useResetState();

  useEffect(() => {
    const syncLocalStorageState = (event?: StorageEvent) => {
      const token = localStorage.getItem('token');
      const organization = localStorage.getItem('organization');
      const project = localStorage.getItem('project');
      const branch = localStorage.getItem('branch');
      const article = localStorage.getItem('article');

      if (token) {
        dispatch(setToken(token));
        dispatch(setIsAuthorized(true));
      } else {
        resetState();

        if (!event) {
          // preventing redirect to events other than storage change
          return;
        }
        navigate('/login', { replace: true });
      }

      dispatch(setCurrentProjectById({ projectId: project }));
      dispatch(setCurrentOrganizationById({ organizationId: organization }));
      dispatch(setCurrentBranchById({ branchId: branch }));
      dispatch(setCurrentArticleById({ articleId: article }));
    };

    syncLocalStorageState();

    window.addEventListener('storage', syncLocalStorageState);

    return () => {
      window.removeEventListener('storage', syncLocalStorageState);
    };
  }, [dispatch, navigate, resetState]);
}
