import React from 'react';
import { Box, Breadcrumbs, Typography, useTheme } from '@mui/material';
import BranchIcon from 'assets/icons/BranchIcon';
import { useAppSelector } from 'store/hooks';
import { currentProject } from 'store/projects/selectors';
import { currentBranch } from 'store/branches/selectors';
import { currentArticle } from 'store/articles/selectors';

function PathBreadcrumbs() {
  const theme = useTheme();
  const project = useAppSelector(currentProject);
  const activeBranch = useAppSelector(currentBranch);
  const activeArticle = useAppSelector(currentArticle);

  return (
    <Breadcrumbs
      sx={{
        display: { xs: 'none', md: 'flex' },
        svg: {
          fill: theme.palette._components.breadcrumbs.fill,
        },
        '.MuiBreadcrumbs-li': {
          display: 'flex',
          alignItems: 'center',
        },
        '.MuiBreadcrumbs-separator': {
          color: theme.palette._components.breadcrumbs.fill,
        },
        px: 3,
        cursor: 'pointer',
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <BranchIcon />
        {project?.name && (
          <Typography variant="body2">{project.name}</Typography>
        )}
      </Box>
      {activeBranch?.name && (
        <Typography variant="body2">{activeBranch.name}</Typography>
      )}
      {activeArticle?.title && (
        <Typography variant="body2">{activeArticle.title}</Typography>
      )}
    </Breadcrumbs>
  );
}

export default PathBreadcrumbs;
