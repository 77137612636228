import React, { Dispatch, RefObject, SetStateAction } from 'react';
import RoundedBox from 'common/RoundedBox/RoundedBox';
import IconButton from '@mui/material/IconButton';
import { Tooltip } from '@mui/material';
import controls from 'pages/Articles/ControlsSection/utils/controls';
import { t } from 'i18next';

interface ControlsSectionProps {
  articleEditorRef: RefObject<HTMLInputElement>;
  currentArticleContent: string;
  setCurrentArticleContent: Dispatch<SetStateAction<string>>;
}

function ControlsSection({
  articleEditorRef,
  currentArticleContent,
  setCurrentArticleContent,
}: ControlsSectionProps) {
  const handleWrapTextWithMarkdown = ({
    mdModifier,
    isMultiline,
    withNewlines,
    modifyTextHeading,
  }: {
    mdModifier?: string;
    isMultiline?: boolean;
    withNewlines?: boolean;
    modifyTextHeading?: boolean;
  }) => {
    if (!articleEditorRef?.current || !mdModifier) {
      return;
    }

    const start = articleEditorRef.current.selectionStart;
    const end = articleEditorRef.current.selectionEnd;

    if (typeof start !== 'number' || typeof end !== 'number') {
      return;
    }

    const selectedText = currentArticleContent.substring(start, end);
    const beforeText = currentArticleContent.substring(0, start);
    const afterText = currentArticleContent.substring(end);

    if (modifyTextHeading) {
      setCurrentArticleContent(
        `${beforeText}${mdModifier}${selectedText}${afterText}`.replace(
          /(#+)\s+(#+)/g,
          (_, leadingHashes, remainingHashes) =>
            `${leadingHashes}${remainingHashes}`,
        ),
      );
      return;
    }

    if (isMultiline) {
      const modifiedText = selectedText
        .split('\n')
        .map((line) => `${mdModifier}${line}`)
        .join('\n');

      setCurrentArticleContent(`${beforeText}${modifiedText}${afterText}`);
      return;
    }

    if (withNewlines) {
      setCurrentArticleContent(
        `${beforeText}${mdModifier}\n${selectedText}\n${mdModifier}${afterText}`,
      );
      return;
    }

    setCurrentArticleContent(
      `${beforeText}${mdModifier}${selectedText}${mdModifier}${afterText}`,
    );
  };

  return (
    <RoundedBox sx={{ p: 1, width: '100%', display: 'flex', flexWrap: 'wrap' }}>
      {controls.map((control) => (
        <Tooltip key={control.label} title={t(control.label)}>
          <IconButton
            disableRipple
            onClick={() => handleWrapTextWithMarkdown({ ...control })}
          >
            {control.icon}
          </IconButton>
        </Tooltip>
      ))}
    </RoundedBox>
  );
}

export default ControlsSection;
