import React, { ReactNode } from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Nav from 'components/Layout/AppDrawer/Nav/Nav';
import { useTheme } from '@mui/material';

interface AppDrawerProps {
  isMobileOpen: boolean;
  handleDrawerClose: () => void;
  handleDrawerTransitionEnd: () => void;
  sideSection?: ReactNode;
}

function AppDrawer({
  isMobileOpen,
  handleDrawerTransitionEnd,
  handleDrawerClose,
  sideSection,
}: AppDrawerProps) {
  const theme = useTheme();

  return (
    <Box component="nav" sx={{ flexShrink: { lg: 0 } }}>
      <Drawer
        variant="temporary"
        open={isMobileOpen}
        onTransitionEnd={handleDrawerTransitionEnd}
        onClose={handleDrawerClose}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          display: { xs: 'flex', lg: 'none' },
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            flexDirection: 'row',
            gap: 1,
            p: 1,
            backgroundColor: theme.vars.palette.background.grey,
          },
        }}
      >
        <Nav handleDrawerClose={handleDrawerClose} />
        {sideSection}
      </Drawer>
      <Drawer
        open
        variant="permanent"
        sx={{
          height: '100%',
          pr: 1,
          display: { xs: 'none', lg: 'flex' },
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            position: 'unset',
            background: 'transparent',
            border: 'none',
            flexDirection: 'row',
            gap: 1,
          },
        }}
      >
        <Nav handleDrawerClose={handleDrawerClose} />
        {sideSection}
      </Drawer>
    </Box>
  );
}

export default AppDrawer;
