import React, { ReactNode } from 'react';
import { SxProps, Typography } from '@mui/material';

interface LabelProps {
  children?: ReactNode;
  sx?: SxProps;
}

function Label({ children, sx }: LabelProps) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      sx={{
        height: 44,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        px: 2,
        textAlign: 'center',
        ...sx,
      }}
    >
      {children}
    </Typography>
  );
}

export default Label;
