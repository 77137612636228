import { ArticleOrigin, ArticleParent } from 'store/articles/interfaces';
import { FormData } from 'components/Forms/ArticleForm/ArticleForm';
import { DefaultBranch } from 'store/branches/interfaces';
import { Language } from 'store/projects/interfaces';

const defaultValues: FormData = {
  language: Language.EN,
  title: '',
  branch: DefaultBranch.master,
  parent: ArticleParent.none,
  origin: ArticleOrigin.none,
};

export default defaultValues;
