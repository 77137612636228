import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import defaultState from 'store/branches/defaultState';
import {
  createBranch,
  deleteBranch,
  editBranch,
  fetchBranches,
} from 'store/branches/thunks';

export const branches = createSlice({
  name: 'branches',
  initialState: defaultState,
  reducers: {
    setCurrentBranchById: (
      draft,
      action: PayloadAction<{ branchId: string | null }>,
    ) => {
      const chosenBranch = draft.branches?.find(
        (branch) => branch.id === action.payload.branchId,
      );

      draft.currentBranch = chosenBranch || null;

      if (!chosenBranch?.id) {
        return;
      }

      localStorage.setItem('branch', chosenBranch.id);
    },

    resetBranchesState: (draft) => {
      draft.branches = defaultState.branches;
      draft.currentBranch = defaultState.currentBranch;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchBranches.pending, (draft) => {
      draft.isRequestPending = true;
    });
    builder.addCase(fetchBranches.fulfilled, (draft, action) => {
      const { 'hydra:member': branches } = action.payload;
      draft.branches = branches;

      const branchId = localStorage.getItem('branch');

      const chosenBranch = draft.branches.find(
        (branch) => branch.id === branchId,
      );

      draft.currentBranch = chosenBranch || null;

      draft.isRequestPending = false;
    });
    builder.addCase(fetchBranches.rejected, (draft) => {
      draft.isRequestPending = false;
    });

    builder.addCase(createBranch.pending, (draft) => {
      draft.isRequestPending = true;
    });
    builder.addCase(createBranch.fulfilled, (draft, action) => {
      const newBranch = action.payload;

      if (draft.branches) {
        draft.branches.push(newBranch);
      } else {
        draft.branches = [newBranch];
      }

      draft.isRequestPending = false;
    });
    builder.addCase(createBranch.rejected, (draft) => {
      draft.isRequestPending = false;
    });

    builder.addCase(editBranch.pending, (draft) => {
      draft.isRequestPending = true;
    });
    builder.addCase(editBranch.fulfilled, (draft, action) => {
      if (!draft?.branches) {
        return;
      }
      const index = draft.branches.findIndex(
        (branch) => branch.id === action.payload.id,
      );
      if (index >= 0) {
        draft.branches[index] = action.payload;
      }
      draft.isRequestPending = false;
    });
    builder.addCase(editBranch.rejected, (draft) => {
      draft.isRequestPending = false;
    });

    builder.addCase(deleteBranch.pending, (draft) => {
      draft.isRequestPending = true;
    });
    builder.addCase(deleteBranch.fulfilled, (draft, action) => {
      if (!draft?.branches) {
        return;
      }
      const index = draft.branches.findIndex(
        (branch) => branch.id === action.payload,
      );
      if (index >= 0) {
        draft.branches.splice(index, 1);
      }
      draft.isRequestPending = false;
    });
    builder.addCase(deleteBranch.rejected, (draft) => {
      draft.isRequestPending = false;
    });
  },
});

export const { setCurrentBranchById, resetBranchesState } = branches.actions;

export default branches.reducer;
