import { Branch, BrandStatus } from 'store/branches/interfaces';
import theme from 'config/mui/theme';

function getBranchStatusColor(branch: Branch) {
  switch (branch.status) {
    case BrandStatus.draft:
      return theme.palette.primary.light;
    case BrandStatus.merged:
      return theme.palette.success.lighter;
    case BrandStatus.closed:
      return theme.palette.secondary.darker;
    default:
      return theme.palette.primary.light;
  }
}

export default getBranchStatusColor;
