import { Language } from 'store/projects/interfaces';

export enum ArticleParent {
  none = 'none',
}

export enum ArticleOrigin {
  none = 'none',
}

export interface ArticlesState {
  isRequestPending: boolean;
  articles: Article[] | null;
  currentArticle: Article | null;
}

export interface Article {
  '@id': string;
  '@type': string;
  id: string;
  project: string;
  parent?: string | null;
  origin?: string | null;
  branch?: string | null;
  language: Language;
  position: number;
  title: string;
  slug?: string;
  readingTime: number;
  metadata: string[];
  content: string;
}

export type CreateArticlePayload = Pick<
  Article,
  'origin' | 'parent' | 'branch' | 'language' | 'title' | 'project'
> & {
  origin: string | null;
};

export type EditArticlePayload = Partial<
  Omit<Article, '@id' | '@type' | 'metadata' | 'project' | 'origin' | 'branch'>
> & { id: string };
