import React, { useEffect, forwardRef } from 'react';
import { Controller, useForm, UseFormSetError } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, TextField } from '@mui/material';
import { t } from 'i18next';
import { InferType } from 'yup';
import { getErrorText } from 'helpers/getErrorText';
import branchFormSchema from 'components/Forms/BranchForm/utils/BranchFormSchema';
import defaultValues from 'components/Forms/BranchForm/utils/defaultValues';
import { Branch } from 'store/branches/interfaces';

export type FormData = InferType<typeof branchFormSchema>;

interface BranchFormProps {
  onSubmit: (data: FormData, setError: UseFormSetError<FormData>) => void;
  branchToEdit?: Branch | null;
}

const BranchForm = forwardRef<HTMLFormElement, BranchFormProps>(
  ({ onSubmit, branchToEdit }, ref) => {
    const {
      control,
      handleSubmit,
      formState: { errors },
      setError,
      reset,
    } = useForm<FormData>({
      resolver: yupResolver(branchFormSchema),
      mode: 'onBlur',
      defaultValues,
    });

    useEffect(() => {
      if (!branchToEdit) {
        return;
      }
      reset(branchToEdit);
    }, [branchToEdit, reset]);

    return (
      <Box
        component="form"
        display="flex"
        width="100%"
        flexDirection="column"
        gap={2}
        onSubmit={handleSubmit((data) => onSubmit(data, setError))}
        ref={ref}
      >
        <Controller
          name="name"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              fullWidth
              type="text"
              label={t('branch_name')}
              variant="outlined"
              error={!!errors.name}
              helperText={getErrorText(errors.name)}
            />
          )}
        />
      </Box>
    );
  },
);

BranchForm.displayName = 'BranchForm';

export default BranchForm;
