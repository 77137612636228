import React from 'react';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import { FormatItalic } from '@mui/icons-material';
import FormatStrikethroughIcon from '@mui/icons-material/FormatStrikethrough';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import CodeIcon from '@mui/icons-material/Code';

const controls = [
  {
    label: 'text_bold',
    icon: <FormatBoldIcon />,
    mdModifier: '**',
  },
  {
    label: 'text_italic',
    icon: <FormatItalic />,
    mdModifier: '*',
  },
  {
    label: 'text_strikethrough',
    icon: <FormatStrikethroughIcon />,
    mdModifier: '~~',
  },
  {
    label: 'modify_heading',
    icon: <TextFieldsIcon />,
    mdModifier: '# ',
    modifyTextHeading: true,
  },
  {
    label: 'numbered_list',
    icon: <FormatListNumberedIcon />,
    mdModifier: '1. ',
    isMultiline: true,
  },
  {
    label: 'bullet_list',
    icon: <FormatListBulletedIcon />,
    mdModifier: '* ',
    isMultiline: true,
  },
  {
    label: 'quote',
    icon: <FormatQuoteIcon />,
    mdModifier: '> ',
    isMultiline: true,
  },
  {
    label: 'code',
    icon: <CodeIcon />,
    mdModifier: '~~~',
    withNewlines: true,
  },
];

export default controls;
