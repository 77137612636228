import React, { useState } from 'react';
import Layout from 'components/Layout/Layout';
import RoundedBox from 'common/RoundedBox/RoundedBox';
import { Typography } from '@mui/material';
import BranchDialog from 'components/Dialogs/BranchDialog/BranchDialog';
import SideSectionContent from 'pages/Branches/SideSectionContent/SideSectionContent';
import { useAppSelector } from 'store/hooks';
import Grid from '@mui/material/Grid2';
import { t } from 'i18next';
import { currentBranch } from 'store/branches/selectors';

function Branches() {
  const [isBranchDialogOpen, setIsBranchDialogOpen] = useState(false);
  const activeBranch = useAppSelector(currentBranch);

  return (
    <>
      <Layout
        sideSection={
          <SideSectionContent setIsBranchDialogOpen={setIsBranchDialogOpen} />
        }
      >
        <RoundedBox sx={{ p: 3, height: '100%' }}>
          <Grid container spacing={2}>
            {activeBranch ? (
              <Grid
                size={12}
                sx={{ gap: 1, display: 'flex', flexDirection: 'column' }}
              >
                <Typography variant="h5">{`${t('active_branch')}: ${activeBranch.name}`}</Typography>
                <Typography variant="body1">{`${t('status')}: ${t(activeBranch.status)}`}</Typography>
              </Grid>
            ) : (
              <Grid size={12}>
                <Typography variant="h5">{t('no_branch_selected')}</Typography>
              </Grid>
            )}
          </Grid>
        </RoundedBox>
      </Layout>
      <BranchDialog
        isDialogOpen={isBranchDialogOpen}
        handleDialogClose={() => setIsBranchDialogOpen(false)}
      />
    </>
  );
}

export default Branches;
