import React, { ReactNode } from 'react';
import { ButtonBase, SxProps, useTheme } from '@mui/material';

interface ActionButtonProps {
  children: ReactNode;
  onClick?: () => void;
  sx?: SxProps;
}

function ActionButton({ children, onClick, sx }: ActionButtonProps) {
  const theme = useTheme();

  return (
    <ButtonBase
      onClick={onClick}
      sx={{
        minHeight: 44,
        borderRadius: theme.shape['borderRadius-s'],
        width: '100%',
        px: 2,
        py: 1,
        gap: 1,
        justifyContent: 'flex-start',
        '&:hover': {
          bgcolor: theme.palette.action.hover,
        },
        ...sx,
      }}
    >
      {children}
    </ButtonBase>
  );
}

export default ActionButton;
