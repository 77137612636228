import * as yup from 'yup';
import { Language } from 'store/projects/interfaces';

const articleFormSchema = yup.object().shape({
  language: yup.string().oneOf(Object.values(Language)).required(),
  title: yup.string().required('article_title_required'),
  branch: yup.string().required().nullable(),
  parent: yup.string().required().nullable(),
  origin: yup.string().required().nullable(),
});

export default articleFormSchema;
