import ReactMarkdown from 'react-markdown';
import { styled } from '@mui/material';

const Markdown = styled(ReactMarkdown)(({ theme }) => ({
  wordBreak: 'break-all',
  hyphens: 'auto',

  blockquote: {
    backgroundColor: theme.palette.background.athensGrey,
    ...theme.applyStyles('dark', {
      backgroundColor: theme.palette.background.grey,
    }),
    borderRadius: 'var(--mui-shape-borderRadius-l)',
    padding: 16,
    margin: 0,
    color: theme.palette.text.secondary,
  },

  pre: {
    margin: 0,
    textWrap: 'wrap',
    borderRadius: 'var(--mui-shape-borderRadius-l)',
    border: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.divider,
    padding: 16,
    color: theme.palette.text.tertiary,
  },

  hr: {
    borderColor: theme.palette.divider,
    borderWidth: 1,
    borderStyle: 'solid',
  },

  '.added-part': {
    padding: 5,
    backgroundColor: theme.palette.success.lighter,
    ...theme.applyStyles('dark', {
      backgroundColor: theme.palette.success.darker,
    }),
    borderRadius: 'var(--mui-shape-borderRadius-l)',
  },

  '.removed-part': {
    padding: 5,
    backgroundColor: theme.palette.error.lighter,
    ...theme.applyStyles('dark', {
      backgroundColor: theme.palette.error.darker,
    }),
    borderRadius: 'var(--mui-shape-borderRadius-l)',
  },
}));

export default Markdown;
