import React from 'react';
import { navWidth } from 'components/Layout/utils/consts';
import RoundedBox from 'common/RoundedBox/RoundedBox';
import { Avatar, Box, Divider, Link as MuiLink, useTheme } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { NavLink } from 'react-router-dom';
import routes from 'components/Layout/AppDrawer/Nav/navRoutes';
import { useAppSelector } from 'store/hooks';
import { currentUser } from 'store/authorization/selectors';
import IconButton from '@mui/material/IconButton';
import CogIcon from 'assets/icons/CogIcon';

interface NavProps {
  handleDrawerClose: () => void;
}

function Nav({ handleDrawerClose }: NavProps) {
  const theme = useTheme();
  const user = useAppSelector(currentUser);

  return (
    <RoundedBox
      sx={{
        minWidth: navWidth,
        height: '100%',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        pt: 1,
        pb: 1.2,
        rowGap: 1,
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Box sx={{ display: { lg: 'none' } }}>
          <IconButton onClick={handleDrawerClose} disableRipple>
            <CloseIcon />
          </IconButton>
          <Divider sx={{ my: 0.5 }} />
        </Box>
        {routes.map((route) => (
          <MuiLink
            key={route.to}
            component={NavLink}
            to={route.to}
            relative="path"
            sx={{
              width: 48,
              height: 48,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: theme.shape['borderRadius-s'],
              svg: {
                fill: theme.palette.background.bombay,
              },
              '&:hover': {
                svg: {
                  fill: theme.palette.primary.main,
                  ...theme.applyStyles('dark', {
                    fill: theme.palette.primary.darker,
                  }),
                },
              },
              '&.active': {
                bgcolor: theme.palette.primary.lighter,
                ...theme.applyStyles('dark', {
                  bgcolor: theme.palette.action.selected,
                }),
                svg: {
                  fill: theme.palette.primary.main,
                  ...theme.applyStyles('dark', {
                    fill: theme.palette.primary.darker,
                  }),
                },
              },
            }}
          >
            {route.icon}
          </MuiLink>
        ))}
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 1,
        }}
      >
        <IconButton disableRipple>
          <CogIcon />
        </IconButton>
        <Avatar
          alt={user ? `${user?.firstname} ${user?.lastname}` : undefined}
          src={user?.avatarUrl}
          sx={{
            width: 40,
            height: 40,
            bgcolor: theme.palette.background.bombay,
            svg: { fill: theme.palette.background.default },
          }}
        />
      </Box>
    </RoundedBox>
  );
}

export default Nav;
